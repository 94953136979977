import React from 'react';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { EquipmentActions, FailureActions, UserActions, useQuery } from '@factorise/api';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FailureStepper from './FailureStepper';
import {
  Equipment,
  EquipmentPart,
  Failure,
  FailureActionReason,
  FailureStatus,
  User,
} from '@factorise/api/types';
import { SimpleDialog } from '@factorise/components';
import FailureInfo from './FailureReportInfo';
import RepairInfo from './FailureRepairInfo';

export interface FailureResolvingProps {
  open: boolean;
  onClose: () => void;
  failureId: number;
  onActionPerformed?: () => void;
  onError?: () => void;
}

const FailureResolving: React.FunctionComponent<FailureResolvingProps> = ({
  open,
  onClose,
  failureId,
  onActionPerformed,
  onError,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const userQuery = useQuery<{ data: { user: User; equipment: Equipment }; }>(UserActions.me());
  const failureQuery = useQuery<{ data: Failure }>(FailureActions.getFailure({ id: failureId }));
  const equipmentId = failureQuery?.response?.payload?.data.equipment.id || 0
  const partsQuery = useQuery<EquipmentPart[]>(EquipmentActions.getEquipmentParts({ id: equipmentId }));
  const reasonsQuery = useQuery<FailureActionReason[]>(FailureActions.getFailureActionReasons());
  const statusesQuery = useQuery<FailureStatus[]>(FailureActions.getFailureStatuses());

  const user = userQuery?.response?.payload?.data?.user;
  const failure = failureQuery?.response?.payload?.data;
  const parts = partsQuery?.response?.payload;
  const reasons = reasonsQuery?.response?.payload;
  const statuses = statusesQuery?.response?.payload;

  const error =
    userQuery.error ||
    failureQuery.error ||
    partsQuery.error ||
    reasonsQuery.error;

  const loading =
    userQuery.loading ||
    failureQuery.loading ||
    partsQuery.loading ||
    reasonsQuery.loading;

  const noData = !user || !failure || !parts || !reasons || !statuses;
  const fetchParts = partsQuery.query
  
  if (!loading && (error || noData)) {
    onError?.();
    enqueueSnackbar(t('modals.failureResolving.fetchingDataError'), {
      variant: 'error',
      preventDuplicate: true,
    });
    return null;
  }

  const actions = (
    <Button autoFocus onClick={onClose} color="secondary">
      {t('general.buttons.cancel')}
    </Button>
  );

  return (
    <SimpleDialog
      fullScreen={fullScreen}
      open={open}
      showLoader={loading}
      title={t('modals.failureResolving.title')}
      actions={actions}
    >
      <FailureInfo failure={failure} />
      <RepairInfo user={user} />
      <FailureStepper
        onPartAdd={() => fetchParts()}
        equipmentId={equipmentId}
        failure={failure}
        reasons={reasons}
        parts={parts}
        statuses={statuses}
        onActionPerformed={onActionPerformed}
      />
    </SimpleDialog>
  );
};

export default FailureResolving;
