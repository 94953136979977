import { Action } from 'react-fetching-library';

interface SortingSettings {
  column: string;
  direction: 'asc' | 'desc';
}

interface AddEquipmentAction {
  name: string;
  levelTag: string;
  parentId?: number;
}

interface AddEquipmentPartAction {
  name: string;
  equipmentId: number;
}

interface DeleteEquipmentAction {
  id: number;
}

interface UpdateEquipmentAction {
  id: number;
  name: string;
}

interface GetEquipmentAction {
  page?: number;
  sorting?: SortingSettings;
  limit?: number;
  filters?: {
    levelTag: string;
  };
}
interface GetEquipmentDetails {
  id: number;
}

export const addEquipmentAction = ({
  name,
  levelTag,
  parentId,
}: AddEquipmentAction): Action => {
  return {
    method: 'POST',
    endpoint: '/api/equipment',
    body: { name, levelTag, parentId },
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const addEquipmentPartAction = ({
  name,
  equipmentId,
}: AddEquipmentPartAction): Action => {
  return {
    method: 'POST',
    endpoint: '/api/equipmentParts',
    body: { name, equipmentId },
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const updateEquipment = ({
  name,
  id,
}: UpdateEquipmentAction): Action => {
  return {
    method: 'PUT',
    endpoint: `/api/equipment/${id}`,
    body: { name },
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const deleteEquipment = ({ id }: DeleteEquipmentAction): Action => {
  return {
    method: 'DELETE',
    endpoint: `/api/equipment/${id}`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const getEquipmentDetails = ({ id }: GetEquipmentDetails): Action => {
  return {
    method: 'GET',
    endpoint: `/api/equipment/${id}`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const getEquipment = ({
  page = 1,
  filters,
  sorting = {
    direction: 'asc',
    column: 'name',
  },
  limit = 10,
}: GetEquipmentAction): Action => {
  const endpoint = '/api/equipment';
  const method = 'GET';
  const offset = page * limit;
  let queryParams: string[] = [];

  if (filters) {
    const { levelTag } = filters;
    levelTag && queryParams.push(`levelTag=${levelTag}`);
  }

  queryParams.push(`limit=${limit}`);
  queryParams.push(`offset=${offset}`);
  queryParams.push(`sortingColumn=${sorting.column}`);
  queryParams.push(`sortingDirection=${sorting.direction}`);

  const action: Action = {
    method,
    endpoint: endpoint + '?' + queryParams.join('&'),
    config: {
      emitErrorForStatuses: [401],
    },
  };
  return action;
};

export const getSingleEquipment = ({ id }: { id: number }): Action => {
  const endpoint = `/api/equipment/${id || 0}`;
  const method = 'GET';

  const action: Action = {
    method,
    endpoint: endpoint,
    config: {
      emitErrorForStatuses: [401],
    },
  };
  return action;
};

export const getEquipmentChildren = ({ id }: { id: number }): Action => {
  return {
    method: 'GET',
    endpoint: `/api/equipment/${id}/children`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const getEquipmentPath = ({ id }: { id: number }): Action => {
  return {
    method: 'GET',
    endpoint: `/api/equipment/${id}/path`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
};

export const getEquipmentParts = ({ id }: { id: number }): Action => {
  return {
    method: 'GET',
    endpoint: `/api/equipment/${id}/parts`,
    config: {
      emitErrorForStatuses: [401],
    },
  };
};
