import React from 'react';
import { Card, CardContent, Grid, TextField, Chip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import { ListBoxOption } from 'types';
import { Failure, FailureReasons } from '@factorise/api/types';
import { SelectWithLabel } from '@factorise/components';
import ReasonInput from './widgets/ReasonsInput';
import AddEquipment from 'modals/equipment/AddEquipment';
import AddReason from 'modals/failure/AddReason';

const styles = {
  productionImpactSelector: {
    margin: 0.5,
    padding: 1,
  },
} as const;

export interface ReportFormProps {
  value?: Partial<Failure>;
  equipment: ListBoxOption[];
  failurePriorities: ListBoxOption[];
  onChange: (key: string, value: string | number | Date | boolean) => void;
  equipmentId?: number;
  parentEquipmentId: number
  fetchEquipment: () => void
  reasons: FailureReasons[]
  reasonsLoading: boolean
  onFailureReasonAdd: (id: number) => void
}

const ReportForm: React.FunctionComponent<ReportFormProps> = ({
  value: fromValues,
  equipment,
  failurePriorities,
  onChange,
  equipmentId,
  parentEquipmentId,
  fetchEquipment,
  reasons,
  reasonsLoading,
  onFailureReasonAdd
}) => {
  const { t } = useTranslation();

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={10} xs={10}>
              <SelectWithLabel
                label={t('modals.failureReporting.equipment')}
                options={equipment}
                required
                name="EQUIPMENT"
                onChange={onTextFieldChange}
                value={fromValues?.equipment?.id || ''}
              />

            </Grid>
            <Grid justifyContent='center' item md={2} xs={2}>
              <AddEquipment onEquipmentAdd={(id) => {
                fetchEquipment?.()
                onChange("EQUIPMENT", id)
              }} parentId={parentEquipmentId || 0} levelTag="MACHINE" />
            </Grid>
            <Grid item md={10} xs={10}>
              <ReasonInput
                label={t('modals.failureReporting.reason')}
                required
                name="REASON"
                onChange={onTextFieldChange}
                value={fromValues?.reason?.id || ''}
                equipmentId={equipmentId}
                reasons={reasons}
                loading={reasonsLoading}
              />
            </Grid>
            <Grid justifyContent='center' item md={2} xs={2}>
              <AddReason onFailureReasonAdd={onFailureReasonAdd} disabled={!equipmentId} equipmentId={equipmentId} />
            </Grid>
            <Grid item md={10} xs={10}>
              <InputLabel style={{ font: '14px', transform: 'scale(0.75)' }}>
                {t('modals.failureReporting.productionImpact')}
              </InputLabel>
              <Box>
                {failurePriorities.map(({ value, label }) => (
                  <Chip
                    sx={styles.productionImpactSelector}
                    onClick={() => onChange('PRIORITY', value)}
                    key={value}
                    label={label}
                    clickable
                    color={
                      fromValues?.priority?.id === value ? 'primary' : 'default'
                    }
                  />
                ))}
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                multiline
                label={t('modals.failureReporting.comment')}
                name="COMMENT"
                onChange={onTextFieldChange}
                value={fromValues?.comment}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default ReportForm;
