import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { set as _set } from 'lodash';
import {
  EquipmentPart,
  FailureAction,
  FailureActionReason,
  Failure,
  FailureStatus,
} from '@factorise/api/types';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  ButtonGroup,
} from '@mui/material';
import { useMutation, Action } from 'react-fetching-library';
import ResolveForm, { ResolveFormState } from './ResolveForm';
import RejectForm, { RejectFormState } from './RejectForm';

const styles = {
  root: {
    width: '100%',
  },
  button: {
    mt: 2,
  },
} as const;


export interface FailureStepperContainerProps {
  reasons?: FailureActionReason[];
  parts?: EquipmentPart[];
  statuses?: FailureStatus[];
  failure?: Failure;
  onActionPerformed?: () => void;
  equipmentId: number
  onPartAdd: Function
}

const FailureStepperContainer: React.FunctionComponent<
  FailureStepperContainerProps
> = ({ reasons, parts, statuses, failure, onActionPerformed, equipmentId, onPartAdd }) => {
  const [rejectFormState, setRejectFormState] =
    useState<Partial<RejectFormState>>();

  const [resolveFormState, setResolveFormState] =
    useState<Partial<ResolveFormState>>();

  // FIXME: needs to be replaced
  const addFailureAction = (failureAction: Partial<FailureAction>): Action => ({
    method: 'POST',
    endpoint: `/api/failures/${failure?.id}/actions`,
    body: failureAction,
  });

  const { mutate, reset } = useMutation(addFailureAction);

  const handleResolveFormChange = (key: string, value: any) => {
    setResolveFormState((prev: Partial<ResolveFormState> | undefined) => {
      const newState = { ...prev } || {};
      if (key === 'PARTS') {
        _set(newState, 'usedParts', value);
      } else if (key === 'COMMENT') {
        _set(newState, 'comment', value);
      }
      return newState;
    });
  };

  const handleRejectFormChange = (key: string, value: any) => {
    setRejectFormState((prev: Partial<RejectFormState> | undefined) => {
      const newState = { ...prev } || {};
      if (key === 'REASON') {
        _set(newState, 'rejectReasons', value);
      } else if (key === 'COMMENT') {
        _set(newState, 'comment', value);
      }
      return newState;
    });
  };

  const onActionAdd = async (type: 'REJECTED' | 'RESOLVED') => {
    reset();
    const statusId = statuses?.find((statuses) => statuses.tag === type)?.id;
    if (!statusId || !failure?.id) {
      return;
    }

    let newFailureAction: Partial<FailureAction> = {};
    _set(newFailureAction, 'failure.id', failure.id);
    _set(newFailureAction, 'status.id', statusId);

    if (type === 'REJECTED') {
      _set(
        newFailureAction,
        'reasons',
        rejectFormState?.rejectReasons?.map((id) => ({ id }))
      );
      _set(newFailureAction, 'comment', rejectFormState?.comment);
    } else if (type === 'RESOLVED') {
      _set(
        newFailureAction,
        'parts',
        resolveFormState?.usedParts?.map((id) => ({ id }))
      );
      _set(newFailureAction, 'comment', resolveFormState?.comment);
    }

    await mutate(newFailureAction);
    onActionPerformed?.();
  };
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [failureAcceptance, setFailureAcceptance] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const confirmButtonEnabled = () => {
    const isResolveFormValid =
      failureAcceptance &&
      resolveFormState?.usedParts &&
      resolveFormState.usedParts.length > 0;
    const isRejectFormValid =
      !failureAcceptance &&
      rejectFormState?.rejectReasons &&
      rejectFormState.rejectReasons.length > 0;

    return isResolveFormValid || isRejectFormValid;
  };

  const backButton = (
    <Button
      onClick={handleBack}
      sx={styles.button}
      color="secondary"
      disabled={activeStep === 0}
    >
      {t('modals.failureResolving.back')}
    </Button>
  );

  const yesNoButtons = (
    <ButtonGroup>
      <Button
        variant="outlined"
        onClick={() => {
          setFailureAcceptance(false);
          handleNext();
        }}
        sx={styles.button}
        color="primary"
      >
        {t('general.buttons.no')}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          setFailureAcceptance(true);
          handleNext();
        }}
        sx={styles.button}
        color="primary"
      >
        {t('general.buttons.yes')}
      </Button>
    </ButtonGroup>
  );

  const confirmButton = (
    <Button
      variant="outlined"
      onClick={() => onActionAdd(failureAcceptance ? 'RESOLVED' : 'REJECTED')}
      sx={styles.button}
      color="primary"
      disabled={!confirmButtonEnabled()}
    >
      {failureAcceptance
        ? t('modals.failureResolving.failureAcceptanceResolve')
        : t('modals.failureResolving.failureAcceptanceMoreActions')}
    </Button>
  );

  return (
    <form style={styles.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0}>
          <StepLabel> {t('modals.failureResolving.step')} 1</StepLabel>
          <StepContent>
            <Typography>
              {t('modals.failureResolving.ableToFixQuestion')}
            </Typography>
            {yesNoButtons}
          </StepContent>
        </Step>
        <Step key={1}>
          <StepLabel>{t('modals.failureResolving.step')} 2</StepLabel>
          <StepContent>
            {failureAcceptance ? (
              <ResolveForm
                onPartAdd={onPartAdd}
                parts={
                  parts?.map((part) => ({
                    value: part.id,
                    label: part.name,
                  })) || []
                }
                equipmentId={equipmentId}
                onChange={handleResolveFormChange}
                formState={resolveFormState}
              />
            ) : (
              <RejectForm
                reasons={
                  reasons?.map((reason) => ({
                    value: reason.id,
                    label: reason.name,
                  })) || []
                }
                onChange={handleRejectFormChange}
                formState={rejectFormState}
              />
            )}
            {backButton} {confirmButton}
          </StepContent>
        </Step>
      </Stepper>
    </form>
  );
};

export default FailureStepperContainer;
