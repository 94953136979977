import React, { useState, useContext } from 'react';
import {
  IconButton,
  Tooltip,
  Grid,
  TextField,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useMutation } from 'react-fetching-library';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts/AuthContext';
import { addEquipmentPartAction } from '@factorise/api/src/Actions/equipment';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Formik, FastField, FormikHelpers } from 'formik';
import { SimpleDialog } from '@factorise/components';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';

export interface AddEquipmentContainerProps {
  onPartAdd?: Function;
  disabled?: boolean;
  equipmentId: number;
}

export interface EquipmentForm {
  name: string;
}

const AddEquipmentContainer: React.FunctionComponent<
  AddEquipmentContainerProps
> = ({ onPartAdd, disabled, equipmentId }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dialogOpened, setDialogOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, reset } = useMutation(addEquipmentPartAction);
  const organizationId = user?.organization.id;
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

  const initialFormValues = { name: '' };
  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('general.formFieldError.min2Char'))
      .max(50, t('general.formFieldError.max50Char'))
      .required(t('general.formFieldError.requiredError')),
  });

  const onFormSubmit = async (values: any, helpers: FormikHelpers<EquipmentForm>) => {
    if (!organizationId) return;
    const { resetForm} = helpers;
    const queryResponse = await mutate({ name: values.name, equipmentId });

    if(queryResponse.error) {
      enqueueSnackbar(t('reports.failures.addEquipmentPart.partAlreadyExists'), {
        variant: 'error',
        disableWindowBlurListener: true,
      });
      return
    } 

    resetForm();
    reset();

    enqueueSnackbar(t('reports.failures.addEquipmentPart.partAdded'), {
      variant: 'success',
      disableWindowBlurListener: true,
    });
    if (onPartAdd) onPartAdd(queryResponse.payload.data.id);
    setDialogOpened(false);
  };

  return (
    <>
      <Tooltip title={t('shopfloor.equipmentStatuses.addEquipment')}>
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={() => setDialogOpened(true)}
          size="large"
        >
          <AddCircleOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Formik
        validateOnChange={false}
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        onSubmit={onFormSubmit}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <SimpleDialog
              fullScreen={fullScreen}
              open={dialogOpened}
              title={t('reports.failures.addEquipmentPart.addPart')}
              actions={
                <>
                  <Button
                    autoFocus
                    onClick={() => setDialogOpened(false)}
                    color="secondary"
                  >
                    {t('general.buttons.cancel')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {t('general.buttons.add')}
                  </Button>
                </>
              }
            >
              <Grid minWidth={320} container direction="column" spacing={3}>
                <Grid item md={12} xs={12}>
                  <FastField
                    fullWidth
                    label={t('reports.failures.addEquipmentPart.part')}
                    required
                    name="name"
                    as={TextField}
                  />
                </Grid>
              </Grid>
            </SimpleDialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEquipmentContainer;
